import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AppNewCluster from './AppNewCluster'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Slider from '@mui/material/Slider';
import {useState} from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Amplify } from "aws-amplify";


import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  


  const [heatMap, setHeatMap] = useState(true);
  const [clusterRadius, setClusterRadius] = useState(35)
  const [totalData, setTotalData ] = useState(0);
  const [earliestDate, setEarliestDate] = useState("");
  const [latestDate, setLatestDate] = useState("");
  const [serverIP, setServerIP] = useState("207.154.194.30:1234");

  const handleChange = (event,newValue) => {
    console.log("yeni value miz:", newValue)

    setClusterRadius(newValue);
  };

  

  function setTotalDataFunction(newTotalDataAmount){
    let estimateClusterRate = newTotalDataAmount / 10 ;

    const notify = () => toast(`⚙️ Our clustering value has been set. For the ${newTotalDataAmount} data points in this set, the new clustering value is ${estimateClusterRate}.`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });


    if(newTotalDataAmount !== totalData){
      notify();
    }  
    setTotalData(newTotalDataAmount)
    setClusterRadius(estimateClusterRate)
    

      
console.log("BURAYA GİRDİKKKKK")
  }

  function setEarliestDateFunction(newEarliestDate){
    setEarliestDate(newEarliestDate)
  }

  function setLatestDateFunction(newLatestDate){
    setLatestDate(newLatestDate)
    
  } 

  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/streets-v11")

  function setMapBoxStyleStreets(){
    setMapStyle("mapbox://styles/mapbox/streets-v11")
  
  }
  function setMapBoxStyleOutdoors(){
    setMapStyle("mapbox://styles/mapbox/outdoors-v11")
  }

  function setMapBoxStyleLight(){
    setMapStyle("mapbox://styles/mapbox/light-v10")
  }
  function setMapBoxStyleDark(){
    setMapStyle("mapbox://styles/mapbox/dark-v10")
  }
  function setMapBoxStyleSatellite(){
    setMapStyle("mapbox://styles/mapbox/satellite-v9")
  }
  function setMapBoxStyleSatelliteStreets(){
    setMapStyle("mapbox://styles/mapbox/satellite-streets-v11")
  }

  function setMapBoxStyleNavigationDay(){
    setMapStyle("mapbox://styles/mapbox/navigation-day-v1")
  }
  function setMapBoxStyleNavigationNight(){
    setMapStyle("mapbox://styles/mapbox/navigation-night-v1")
  }




























  return (
    <div>
      
    <Box sx={{ display: 'flex', 
    
    }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <img src="./venit.png" height="48px"alt="venit logo"/>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 , marginLeft:"33%"  , display: { xs: 'none', sm: 'none', md:"none" , lg:"inline-block", xl:"inline-block"} }} component="div">
          Vehicular Networking and Intelligent Transportation Systems Research Lab
          </Typography>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 , marginLeft:"40%"  , display: { lg:'none', xl:'none' } }} component="div">
          VeNIT
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
      <AppNewCluster mapStyle={mapStyle} 
      clusterRadius={clusterRadius} 
      setTotalDataFunction={setTotalDataFunction}  setEarlistDateFunction={setEarliestDateFunction} 
      setLatestDateFunction={setLatestDateFunction} heatMap={heatMap} serverIP={serverIP}/>
      
      </Main>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "#1976d2",
            color: "white",
          }
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          backgroundColor: 'primary.dark',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <h3 style={{textAlign:"center", marginBottom:"-1rem"}}>Map Info</h3>
        <List>
         
            <ListItem key={"MapInfo-1"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {2 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={"Total Count: "+ totalData}  sx={{color:"white"}}/>
              </ListItemButton>
            </ListItem>

            <ListItem key={"MapInfo-2"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {2 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={"Last Date: "+ earliestDate} sx={{color:"white"}}/>
              </ListItemButton>
            </ListItem>


            <ListItem key={"MapInfo-3"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {2 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={"First & Past Date: "+ latestDate} sx={{color:"white"}} />
              </ListItemButton>
            </ListItem>
          
        </List>
        <Divider />
        <h3 style={{textAlign:"center"}}>Map Setting</h3>
        <h5 style={{textAlign:"center", marginTop:"-15px", marginBottom:"0px"}}>Marker Type</h5>
        <Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
>
        <ButtonGroup sx={{marginLeft:"7px"}}color="warning" variant="contained">
  <Button onClick={() => setHeatMap(false)}>Road Bump</Button>
  <Button  onClick={() => setHeatMap(true)}>Heat Map</Button>
</ButtonGroup>
</Box>
        
        <h5 style={{textAlign:"center"}}>Cluster Radius</h5>
        <Box sx={{width:"200px", marginTop:"-20px", marginLeft:"15%"}} color="secondary"     display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center" >
      <Slider
        aria-label="Cluster Range"
        value={clusterRadius}
        onChange={handleChange}
        valueLabelDisplay="auto"
        step={10}
        marks
        min={0}
        max={210}
        color="success"
      />
    </Box>
    <Divider />



    <h3 style={{textAlign:"center"}}>Map Style</h3>
    <h5 style={{textAlign:"center", marginTop:"-15px",  marginBottom:"0px"}}>Street Style</h5>
    <Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
>
    <ButtonGroup color="secondary" variant="contained"  sx={{textAlign:"center"}}>
  <Button onClick={setMapBoxStyleStreets} >Street</Button>
  <Button onClick={setMapBoxStyleOutdoors} >outdoors</Button>
</ButtonGroup>
</Box>



<h5 style={{textAlign:"center",  marginBottom:"0px"}}>Day Style</h5>
<Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
>
<ButtonGroup color="secondary" variant="contained"  sx={{alignItems:"center"}}>
  <Button  onClick={setMapBoxStyleLight} >Light</Button>
  <Button  onClick={setMapBoxStyleDark} >Dark</Button>
</ButtonGroup>
</Box>



<h5 style={{textAlign:"center",  marginBottom:"0px"}}>Satellite Style</h5>
<Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
>
<ButtonGroup color="secondary" variant="contained" sx={{textAlign:"center"}}>
<Button  onClick={setMapBoxStyleSatelliteStreets} >Streets</Button>
  <Button onClick={setMapBoxStyleSatellite} >Plain</Button>
</ButtonGroup>
</Box>




<Box 
    display="flex" 
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
>
<h5 style={{textAlign:"center",  marginBottom:"0px"}}>Navigation Style</h5>
<ButtonGroup color="secondary" variant="contained">
  <Button onClick={setMapBoxStyleNavigationDay}  >Day</Button>
  <Button onClick={setMapBoxStyleNavigationNight} >Night</Button>
</ButtonGroup>
</Box>

<Divider />



<h3 style={{textAlign:"center"}}>Server Setting</h3>



      </Drawer>
      
      

      
    </Box>
    
    </div>
  );
}
