
import './App.css';
import Map, {
  Marker, Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import Supercluster from 'supercluster';
import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from '@mui/material';
import { ToastContainer} from 'react-toastify';
import * as queries from './graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from "./graphql/subscriptions";
import * as mutations from './graphql/mutations';
import Papa from 'papaparse';

const TOKEN = 'pk.eyJ1IjoidmVuaXRoYXJpdGEiLCJhIjoiY2xzaXoxOGJpMWQybzJrbXIwcTV0dnE0dyJ9.qwTFJIEI4LSeTX2dzRFjHA' // Set your mapbox token here
const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;


const SIZE = 20;
// https://207.154.194.30:1234/bumpit/?limit=10000
// https://data.police.uk/api/crimes-street/all-crime?lat=52.629729&lng=-1.131592&date=2019-10
function App({ mapStyle, clusterRadius, setTotalDataFunction, setEarlistDateFunction, setLatestDateFunction, heatMap, serverIP }) {
  const mapRef = useRef();




  const [points, setPoints] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = useState(0);

  const [dateDifference, setDateDifference] = useState(0);
  const [dateFirst, SetDateFirst] = useState(0);

  const supercluster = new Supercluster({
    radius: clusterRadius,
    maxZoom: 20,
  });

  const geolocateStyle = {
    top: 0,
    left: 0,
    padding: '10px'
  };

  const fullscreenControlStyle = {
    top: 36,
    left: 0,
    padding: '10px'
  };

  const navStyle = {
    top: 72,
    left: 0,
    padding: '10px'
  };

  const scaleControlStyle = {
    bottom: 36,
    left: 0,
    padding: '10px'
  };


  useEffect(() => {
    let pointArray = [];
    fetch('/data/bumpit_results.csv')
      .then(response => response.text())
      .then(text => {
        const bump = Papa.parse(text, { header: true }).data;
        
        for(let i = 0; i < bump.length; i++){
          let point = {
            type: "Feature",
            properties: { cluster: false, bumpID: bump[i].id, category: bump[i].createdAt, detectionTime: bump[i].updatedAt, type: "bump" },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(bump[i].lng ? bump[i].lng : 0),
                parseFloat(bump[i].lat ? bump[i].lat : 0)
              ]
            }
          }
          pointArray.push(point)
        }
        setPoints(pointArray); // Moved inside the then block
      })
      .catch(error => console.error(error));
  }, []);
  
  useEffect(() => {
    let pointArray = [];
    fetch('/data/pit_results.csv')
      .then(response => response.text())
      .then(text => {
        const pit = Papa.parse(text, { header: true }).data;
        
        for(let i = 0; i < pit.length; i++){
          let point = {
            type: "Feature",
            properties: { cluster: false, bumpID: pit[i].id, category: pit[i].createdAt, detectionTime: pit[i].updatedAt, type: "pit" },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(pit[i].lng ? pit[i].lng : 0),
                parseFloat(pit[i].lat ? pit[i].lat : 0)
              ]
            }
          }
          pointArray.push(point)
        }
        setPoints(points => [...points, ...pointArray]); // Correctly merge with existing points
      })
      .catch(error => console.error(error));
  }, []);


  useEffect(() => {
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, zoom));
  }, [points, zoom, bounds]);



  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef?.current]);


  function calculateColor(pointDate) {
    let millisecondsDifference = Date.parse(pointDate) - dateFirst;
    let differenceDenominator = dateDifference / 225.0
    let colorRedCode = Math.round(millisecondsDifference / differenceDenominator)
    let colorBlue = 225 - colorRedCode;
    return (`rgb(${colorRedCode},0,${colorBlue})`)
  }



  return (


      <div className='mapStyle'>
        <Map
          initialViewState={{ latitude: 40.906400, longitude: 29.155772, zoom: 10 }}
          style={{ width: '100vw', height: 'calc(100vh - 56px)' ,marginTop:"-9px" }}
          mapStyle={mapStyle}
          mapboxAccessToken={TOKEN}
          ref={mapRef}
          onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}

        >


          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`
                    }}

                    onClick={() => {
                      console.log("cluster id:", cluster)
                      const zoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                      mapRef.current.flyTo({
                        center: [longitude, latitude],
                        zoom,
                        speed: 1,
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={`bump-${cluster.properties.bumpID}`}
                latitude={latitude}
                longitude={longitude}
              >
                <Tooltip title={cluster.properties.detectionTime}>


                  {heatMap ? (
                    <svg
                      height={SIZE}
                      viewBox="0 0 24 24"
                      style={{
                        cursor: 'pointer',
                        fill: calculateColor(cluster.properties.detectionTime),
                        stroke: 'none',
                      }}
                    >
                      <path d={ICON} />
                    </svg>
                  ) :
                    (
                      <button className="bump-marker">
                        {cluster.properties.type === "bump" ?
                        <img src=".\Speed_bump.svg" alt="be careful drive" /> :
                        <img src=".\Speed_pit.svg" alt="be careful drive" />
                        }
                        
                      </button>
                    )
                  }



                </Tooltip>
              </Marker>
            );
          })}
          
          <GeolocateControl style={geolocateStyle} />
          <FullscreenControl style={fullscreenControlStyle} />
          <NavigationControl style={navStyle} />
          <ScaleControl style={scaleControlStyle} />

        </Map>
        <ToastContainer />
        
      </div>
   
  );
}

export default App;


/*
        
<button onClick={() => updatePoint("106f60e6-5dea-48b3-9447-a59b5473ac1c")}>updateBump</button>

*/